import { useEffect } from "react";
import authmanager from "./utils/authmanager";
import Login from "./pages/Login";
import { Routes, Route } from "react-router-dom";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import Calendar from "./pages/Calendar";
import Checkout from "./pages/Checkout";
import Bookings from "./pages/Bookings";
import Layout from "./components/Layout";
import Account from "./pages/Account";

function App() {
  useEffect(() => {
    if (authmanager.getAccessToken()) {
      authmanager.sync();
    }
  }, []);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/calendar/:id" element={<Calendar />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/bookings" element={<Bookings />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/account" element={<Account />} />
        <Route path="*" element={<Dashboard />} />
      </Routes>
    </Layout>
  );
}

export default App;
