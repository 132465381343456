import { Datepicker } from "vanillajs-datepicker";
import React, { useEffect, useRef, useState } from "react";
import axiosClient from "../utils/axios";
import { addDays, addMinutes, format } from "date-fns";
import fr from "date-fns/locale/fr";
import { cart } from "../utils/cart";
import { useNavigate } from "react-router-dom";
import Schedule from "../models/Schedule";

Object.assign(Datepicker.locales, {
  fr: {
    days: [
      "dimanche",
      "lundi",
      "mardi",
      "mercredi",
      "jeudi",
      "vendredi",
      "samedi",
    ],
    daysShort: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
    daysMin: ["d", "l", "ma", "me", "j", "v", "s"],
    months: [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre",
    ],
    monthsShort: [
      "janv.",
      "févr.",
      "mars",
      "avril",
      "mai",
      "juin",
      "juil.",
      "août",
      "sept.",
      "oct.",
      "nov.",
      "déc.",
    ],
    today: "Aujourd'hui",
    monthsTitle: "Mois",
    clear: "Effacer",
    weekStart: 1,
    format: "dd/mm/yyyy",
  },
});

const SlotPicker = ({ calendar, value }) => {
  const datePicker = useRef();
  const inputRef = useRef();
  const [date, setDate] = useState(null);
  const [slots, setSlots] = useState({ loading: false, list: [] });
  const [cartList, setCartList] = useState(cart.getValue());
  const navigate = useNavigate();
  const calendarCart = cartList.filter((s) => s.calendar_id === calendar.id);

  useEffect(() => {
    const sub = cart.subscribe(setCartList);

    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    if (calendar && date) {
      _loadSlots();
    }
  }, [calendar, date]);

  useEffect(() => {
    if (inputRef.current) {
      datePicker.current = new Datepicker(inputRef.current, {
        format: "dd/mm/yyyy",
        language: "fr",
      });

      inputRef.current.addEventListener("changeDate", _changeDateListener);

      datePicker.current.setDate(new Date());
    }

    return () => {
      if (inputRef.current) {
        datePicker.current.destroy();
        inputRef.current.removeEventListener("changeDate", _changeDateListener);
      }
    };
  }, [inputRef.current]);

  // useEffect(() => {
  //   setActiveSlot(null);
  // }, [calendar]);

  const _getAllSlots = (from, to) => {
    if (!date) {
      return [];
    }

    const openHours = [9, 22];

    const _slots = [];
    let startDate = new Date(from);
    const endDate = new Date(to);

    while (startDate < endDate) {
      const _endSlot = addMinutes(
        startDate,
        calendar.attributes.base_duration || 60
      );

      if (
        startDate.getHours() >= openHours[0] &&
        (_endSlot.getHours() || 24) <= openHours[1]
      ) {
        const slot = new Schedule({
          from: startDate,
          to: _endSlot,
          calendar_id: calendar.id,
        });
        _slots.push(slot);
      }

      startDate = _endSlot;
    }

    return _slots;
  };

  const _loadSlots = async () => {
    const {
      data: { data },
    } = await axiosClient.get("/api/d2/contacts/schedules", {
      params: {
        after: date,
        before: addDays(date, 1),
      },
    });

    const slots = data.map(Schedule.fromApi);

    setSlots((d) => ({ ...d, list: slots }));
  };

  const _bookSlot = async () => {
    if (!calendarCart?.length) {
      return;
    }

    navigate("/checkout");
  };

  const _changeDateListener = (e) => {
    if (e.detail.date !== date) {
      setDate(e.detail.date);
    }
  };

  const toggleSlot = (slot) => {
    let _cartList = [...cartList];
    const found = _cartList.find((s) => s.identifier === slot.identifier);
    if (found) {
      _cartList = _cartList.filter((s) => s !== found);
    } else {
      _cartList = _cartList.concat(slot);
    }

    cart.next(_cartList);
  };

  const allSlots = _getAllSlots(date, addDays(date, 1));

  return (
    <div className="space-y-4">
      <div className="relative flex items-start">
        <div style={{ width: 300 }} className="flex-shrink-0">
          <input ref={inputRef} type="text" className="hidden" />
        </div>

        <fieldset>
          <legend className="sr-only">Slot</legend>
          <div className="flex space-x-6">
            <div>
              {allSlots.slice(0, 7).map((slot) => {
                const booked = slots.list.find((s) => {
                  return (
                    s.to >= slot.from &&
                    s.from <= slot.to &&
                    s.from >= slot.from
                  );
                });

                slot.disabled = slot.to <= Date.now() || booked;
                slot.active = Boolean(
                  cartList.find((s) => s.identifier == slot.identifier)
                );

                return (
                  <div
                    className={`relative flex items-center py-3 ${
                      slot.disabled ? "opacity-50" : "cursor-pointer"
                    }`}
                    onClick={() => (slot.disabled ? null : toggleSlot(slot))}
                  >
                    <input
                      type="checkbox"
                      name="slot"
                      aria-labelledby={slot.identifier}
                      checked={slot.active}
                      className="focus:ring-primary h-4 w-4 text-primary border-gray-300"
                    />
                    <div className="ml-2 text-sm flex items-center space-x-4">
                      <label
                        htmlFor="small"
                        className="font-medium text-gray-700"
                      >
                        {format(slot.from, "p", { locale: fr })} -{" "}
                        {format(slot.to, "p", { locale: fr })}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
            <div>
              {allSlots.slice(7).map((slot) => {
                const booked = slots.list.find((s) => {
                  return (
                    s.to >= slot.from &&
                    s.from <= slot.to &&
                    s.from >= slot.from
                  );
                });

                slot.disabled = slot.to <= Date.now() || booked;
                slot.active = Boolean(
                  cartList.find((s) => s.identifier == slot.identifier)
                );

                return (
                  <div
                    className={`relative flex items-center py-3 ${
                      slot.disabled ? "opacity-50" : "cursor-pointer"
                    }`}
                    onClick={() => (slot.disabled ? null : toggleSlot(slot))}
                  >
                    <input
                      type="checkbox"
                      name="slot"
                      aria-labelledby={slot.identifier}
                      checked={slot.active}
                      className="focus:ring-primary h-4 w-4 text-primary border-gray-300"
                    />
                    <div className="ml-2 text-sm flex items-center space-x-4">
                      <label
                        htmlFor="small"
                        className="font-medium text-gray-700"
                      >
                        {format(slot.from, "p", { locale: fr })} -{" "}
                        {format(slot.to, "p", { locale: fr })}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </fieldset>
      </div>
      <div>
        {calendarCart.length ? (
          <button
            type="button"
            className="inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            onClick={_bookSlot}
          >
            Confirmer votre réservation
          </button>
        ) : (
          <button
            type="button"
            className="inline-flex justify-center rounded-md px-4 py-2 text-sm font-medium border border-gray-300 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 opacity-50"
          >
            Confirmer votre réservation
          </button>
        )}
      </div>
    </div>
  );
};

export default SlotPicker;
