import { useEffect, useState } from "react";
import authmanager from "./authmanager";

function useLoggedGuard() {
  const [logged, setLogged] = useState(authmanager.logged);

  useEffect(() => {
    const sub = authmanager.loggedSubject.subscribe(setLogged);

    return () => sub.unsubscribe();
  }, []);

  return !!logged;
}

export default useLoggedGuard;
