import React, { useEffect, useState } from "react";
import axiosClient from "../utils/axios";
import authmanager from "../utils/authmanager";

const ProfileForm = ({ onSubmit, account }) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});

  useEffect(() => {
    if (account?.attributes) {
      const { fullname } = account.attributes;
      const [first_name, last_name] = fullname.split(" ");
      setValues({ ...account.attributes, first_name, last_name });
    }
  }, [account]);

  const _handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);

    try {
      //   const {
      //     data: { access_token },
      //   } = await axiosClient.post("/api/d2/contacts/update", {
      //     data: {
      //       attributes: {
      //         superfields: {
      //           given_name: values.first_name,
      //           surname: values.last_name,
      //           email_address_1: values.email,
      //         },
      //         title: `${values.first_name} ${values.last_name}`,
      //       },
      //     },
      //   });
      //
      //   await axiosClient.post(
      //     "/api/d2/contacts/me/change_password_on_first_connection",
      //     {
      //       password: values.password,
      //     },
      //     { headers: { Authorization: `Bearer ${access_token}` } }
      //   );
      //
      //   await authmanager.login(credentials);

      onSubmit && onSubmit();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      className="space-y-6"
      action="#"
      method="POST"
      onSubmit={_handleSubmit}
    >
      <div className="grid grid-cols-2 gap-6">
        <div>
          <label
            htmlFor="first_name"
            className="block text-sm font-medium text-gray-700"
          >
            Prénom{" "}
          </label>
          <div className="mt-1">
            <input
              id="first_name"
              name="first_name"
              type="text"
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              value={values.first_name}
              onChange={({ currentTarget: { value: first_name } }) =>
                setValues((c) => ({ ...c, first_name }))
              }
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Nom{" "}
          </label>
          <div className="mt-1">
            <input
              id="last_name"
              name="last_name"
              type="last_name"
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              value={values.last_name}
              onChange={({ currentTarget: { value: last_name } }) =>
                setValues((c) => ({ ...c, last_name }))
              }
            />
          </div>
        </div>
      </div>
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          {" "}
          Adresse email{" "}
        </label>
        <div className="mt-1">
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            value={values.email}
            onChange={({ currentTarget: { value: email } }) =>
              setValues((c) => ({ ...c, email }))
            }
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="password"
          className="block text-sm font-medium text-gray-700"
        >
          {" "}
          Mot de passe{" "}
        </label>
        <div className="mt-1">
          <input
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            value={values.password}
            onChange={({ currentTarget: { value: password } }) =>
              setValues((c) => ({ ...c, password }))
            }
          />
        </div>
      </div>
      <div>
        {loading ? (
          <button
            type="button"
            className="inline-flex justify-center rounded-md px-4 py-2 text-sm font-medium border border-gray-300 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 opacity-50"
          >
            Chargement ...
          </button>
        ) : (
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            Modifier mon compte
          </button>
        )}
      </div>
    </form>
  );
};

export default ProfileForm;
