import React, { useEffect, useState } from "react";
import useLoggedGuard from "../utils/useLoggedGuard";
import Login from "./Login";
import useCurrentAccount from "../utils/useCurrentAccount";
import axiosClient from "../utils/axios";
import ScheduleTile from "../components/ScheduleTile";
import Schedule from "../models/Schedule";

const Bookings = () => {
  const logged = useLoggedGuard();
  const account = useCurrentAccount();

  const [schedules, setSchedules] = useState([]);

  const _loadSchedules = async () => {
    const {
      data: { data },
    } = await axiosClient.get("/api/d2/contacts/schedules?me_as_user=true");

    setSchedules(data.map(Schedule.fromApi));
  };

  useEffect(() => {
    if (account) {
      _loadSchedules();
    }
  }, [account]);

  if (!logged) {
    return <Login />;
  }

  return (
    <div>
      <header>
        <div className="px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">
            Mes réservations
          </h1>
        </div>
      </header>
      <div className="space-y-4 px-4 sm:px-6 lg:px-8 mt-6">
        {schedules.map((item) => (
          <ScheduleTile schedule={item} />
        ))}
      </div>
    </div>
  );
};

export default Bookings;
