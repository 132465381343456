import RxjsAuth, { AuthTokenStorage } from "rxjs-auth";
import axiosClient from "./axios";

const authmanager = RxjsAuth.create("lig-golf", {
  login: (c) => {
    return axiosClient.post(`${process.env.REACT_APP_HOST}/api/pin_token`, c, {
      params: {
        hours: 8760,
      },
    });
  },
  getAccessToken: (res) => res.data.access_token,
  fetchUser: () => {
    return axiosClient
      .get(`${process.env.REACT_APP_HOST}/api/d2/contacts/me`, {
        crossdomain: true,
      })
      .then((r) => r.data.data);
  },
  tokenStorage: AuthTokenStorage.localStorage,
});

export default authmanager;
