import React, { useEffect, useState } from "react";
import axiosClient from "../utils/axios";
import { format } from "date-fns";
import fr from "date-fns/locale/fr";

const ScheduleTile = ({ schedule: item, controls }) => {
  const [calendar, setCalendar] = useState(null);

  const _reloadCalendar = async () => {
    setCalendar(null);
    try {
      const {
        data: { data },
      } = await axiosClient.get(
        `/api/d2/contacts/calendars/${item.calendar_id}`
      );
      setCalendar(data);
    } catch (e) {
      setCalendar(null);
    }
  };

  useEffect(() => {
    _reloadCalendar();
  }, [item.calendar_id]);

  if (!calendar) {
    return null;
  }

  return (
    <div className="flex space-x-4 w-full">
      <img
        src="/images/calendar-hero.jpg"
        alt={calendar.attributes.title}
        className="w-52 h-52 object-cover opacity-75 flex-shrink-0"
      />
      <div className="space-y-1 w-full">
        <div className="font-bold text-xl w-full border-b border-black">
          {calendar.attributes.title}
        </div>
        <div className="text-gray-500">
          Le {format(item.from, "P", { locale: fr })} de{" "}
          {format(item.from, "p", { locale: fr })} à{" "}
          {format(item.to, "p", { locale: fr })}
        </div>
      </div>
      {controls || null}
    </div>
  );
};

export default ScheduleTile;
