import React, { useEffect, useState } from "react";
import axiosClient from "../utils/axios";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const [calendars, setCalendars] = useState([]);

  const _loadCalendars = async () => {
    const {
      data: { data },
    } = await axiosClient.get("/api/d2/contacts/calendars");
    setCalendars(data || []);
  };

  useEffect(() => {
    _loadCalendars();
  }, []);

  return (
    <div className="space-y-4 px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {calendars.map((calendar) => (
          <Link
            className="group inline-flex items-center justify-center relative bg-black"
            to={`/calendar/${calendar.id}`}
          >
            <img
              src="/images/calendar-hero.jpg"
              alt={calendar.attributes.title}
              className="w-full h-64 object-cover opacity-75"
            />
            <div className="absolute text-2xl text-white">
              <div className="mb-2 font-bold">{calendar.attributes.title}</div>
              <div className="w-full text-center">
                <button className="text-white inline-flex justify-center rounded-md px-3 py-1.5 text-sm font-medium bg-black focus:outline-none flex items-center group-hover:bg-primary">
                  Réserver
                </button>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
