import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosClient from "../utils/axios";
import SlotPicker from "../components/SlotPicker";
import { layoutCurrentCalendar } from "../components/Layout";

const Calendar = () => {
  const { id } = useParams();
  const [calendar, setCalendar] = useState(null);

  const _reloadCalendar = async () => {
    try {
      const {
        data: { data },
      } = await axiosClient.get(`/api/d2/contacts/calendars/${id}`);
      setCalendar(data);
    } catch (e) {
      setCalendar(null);
    }
  };

  useEffect(() => {
    if (calendar) {
      layoutCurrentCalendar.next(calendar);
    }

    return () => layoutCurrentCalendar.next(null);
  }, [calendar]);

  useEffect(() => {
    _reloadCalendar();
  }, [id]);

  if (!calendar) {
    return null;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8 w-full flex justify-center">
      <SlotPicker calendar={calendar} />
    </div>
  );
};

export default Calendar;
