import { Link } from "react-router-dom";
import useCurrentAccount from "../utils/useCurrentAccount";
import { Menu, Transition } from "@headlessui/react";
import React from "react";
import authmanager from "../utils/authmanager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";

const Appbar = () => {
  const account = useCurrentAccount();

  return (
    <div className="w-full bg-black">
      <div className="flex h-28 items-center justify-between w-full max-w-screen-xl mx-auto">
        <div />
        <Link to="/" className="font-bold text-white text-center">
          <img src="/images/logo-white.png" alt="logo" className="h-20" />
        </Link>
        <div className="relative text-right">
          {account ? (
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="text-white inline-flex w-full justify-center rounded-md px-4 py-2 font-medium bg-primary focus:outline-none flex items-center hover:bg-primary-hover">
                  <FontAwesomeIcon icon={faUserCircle} className="mr-2" />
                  {account.attributes.fullname}
                </Menu.Button>
              </div>
              <Transition
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          className={`text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          {account.attributes.fullname}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/account"
                          className={`${
                            active ? "bg-primary text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          Mes informations
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/bookings"
                          className={`${
                            active ? "bg-primary text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          Mes réservations
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => authmanager.logout()}
                          type="button"
                          className={`${
                            active ? "bg-primary text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          Déconnexion
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          ) : (
            <Link to="/login">
              <button className="text-white inline-flex w-full justify-center rounded-md px-4 py-2 font-medium bg-primary focus:outline-none flex items-center hover:bg-primary-hover">
                <FontAwesomeIcon
                  icon={faArrowRightToBracket}
                  className="mr-2"
                />
                Mon compte
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Appbar;
