import { Link, useNavigate } from "react-router-dom";
import SigninForm from "../components/SigninForm";
import { useEffect, useState } from "react";
import authmanager from "../utils/authmanager";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState();

  useEffect(() => {
    const sub = authmanager.loadingSubject.subscribe(setLoading);

    return () => sub.unsubscribe();
  }, []);

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Connectez-vous
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Ou{" "}
          <Link
            to="/register"
            className="font-medium text-primary hover:underline"
          >
            inscrivez-vous
          </Link>
        </p>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className={`bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10`}>
          <SigninForm onSubmit={() => navigate("/")} loading={loading} />
        </div>
      </div>
    </div>
  );
};

export default Login;
