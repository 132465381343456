import React from "react";
import authmanager from "../utils/authmanager";
import { useEffect, useState } from "react";
import axiosClient from "../utils/axios";
import { Link, NavLink } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import useCurrentAccount from "../utils/useCurrentAccount";

const Navbar = () => {
  const [calendars, setCalendars] = useState([]);
  const [showMoblieMenu, setShowMobileMenu] = useState(false);
  const account = useCurrentAccount();

  const _loadCalendars = async () => {
    const {
      data: { data },
    } = await axiosClient.get("/api/d2/contacts/calendars");
    setCalendars(data || []);
  };

  useEffect(() => {
    _loadCalendars();
  }, []);

  return (
    <nav className="bg-black shadow-sm">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-20">
          <div className="flex">
            <div className="hidden sm:flex sm:space-x-8">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  `inline-flex items-center px-1 pt-1 border-b-4 text-base font-medium ${
                    isActive
                      ? "border-primary text-white"
                      : "border-transparent text-gray-300 hover:text-gray-100 hover:border-gray-300"
                  }`
                }
              >
                Accueil
              </NavLink>
              {calendars.map((calendar) => (
                <NavLink
                  to={`/calendar/${calendar.id}`}
                  className={({ isActive }) =>
                    `inline-flex items-center px-1 pt-1 border-b-4 text-base font-medium ${
                      isActive
                        ? "border-primary text-white"
                        : "border-transparent text-gray-300 hover:text-gray-100 hover:border-gray-300"
                    }`
                  }
                >
                  {calendar.attributes.title}
                </NavLink>
              ))}
            </div>
          </div>
          <div className="-mr-2 flex items-center sm:hidden">
            {/* Mobile menu button */}
            <button
              type="button"
              className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              aria-controls="mobile-menu"
              onClick={() => setShowMobileMenu((s) => !s)}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {showMoblieMenu ? (
        <div className="sm:hidden" id="mobile-menu">
          <div className="pt-2 pb-3 space-y-1">
            {calendars.map((calendar) => (
              <NavLink
                to={`/calendar/${calendar.id}`}
                className={({ isActive }) =>
                  `block pl-3 pr-4 py-2 border-l-4 text-base font-medium ${
                    isActive
                      ? "bg-blue-50 border-primary text-primary-hover"
                      : "border-transparent text-white hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800"
                  }`
                }
              >
                {calendar.attributes.title}
              </NavLink>
            ))}
          </div>
          <div className="pt-4 pb-3 border-t border-gray-200">
            <div className="flex items-center px-4">
              <div>
                <div className="text-base font-medium text-white">
                  {account.attributes.fullname}
                </div>
                <div className="text-sm font-medium text-white">
                  {account.attributes.email}
                </div>
              </div>
            </div>
            <div className="mt-3 space-y-1">
              <Link
                to="/bookings"
                className="block px-4 py-2 text-base font-medium text-white hover:text-gray-800 hover:bg-gray-100"
              >
                Mes réservations
              </Link>
              <button
                onClick={() => authmanager.logout()}
                type="button"
                className="block px-4 py-2 text-base font-medium text-white hover:text-gray-800 hover:bg-gray-100"
              >
                Déconnexion
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </nav>
  );
};

export default Navbar;
