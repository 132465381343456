import { addMinutes } from "date-fns";

class Schedule {
  identifier;
  from;
  to;

  constructor(data) {
    this.from = new Date(data.from);
    this.to = new Date(data.to);
    this.calendar_id = data.calendar_id;

    this.identifier = JSON.stringify({
      calendar_id: this.calendar_id,
      from: this.from,
      to: this.to,
    });
  }

  static fromApi(data) {
    const from = new Date();
    from.setSeconds(0);
    from.setFullYear(data.attributes.ryears[0]);
    from.setMonth(data.attributes.rmonths[0] - 1);
    from.setDate(data.attributes.rmdays[0]);
    from.setHours(data.attributes.rhours[0] + 2);
    from.setMinutes(data.attributes.rminutes[0]);

    const to = addMinutes(from, 60);

    return new Schedule({
      from,
      to,
      calendar_id: data.attributes.calendar_id,
    });
  }
}

export default Schedule;
