import { useEffect, useState } from "react";
import authmanager from "./authmanager";

const useCurrentAccount = function () {
  const [account, setAccount] = useState(null);

  useEffect(() => {
    const sub = authmanager.userSubject.subscribe((a) => setAccount(a));

    return () => sub.unsubscribe();
  }, []);

  return account;
};

export default useCurrentAccount;
