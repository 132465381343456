import axios from "axios";
import authmanager from "./authmanager";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_HOST,
});

axiosClient.interceptors.request.use((config) => {
  config.headers = config.headers || {};

  if (
    config.headers.Authorization === undefined ||
    config.headers.Authorization === "Bearer undefined"
  ) {
    const token = authmanager.getAccessToken();
    if (token && token !== "undefined") {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      config.headers.Authorization = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7ImVtYWlsIjpudWxsLCJmdWxsbmFtZSI6IiIsImlkIjoiNjJiMDE3YmQwN2JjMmIzZTBhNmIyZGMxIn0sImV4cCI6MTY4NzI0ODM0MH0.f8fVqZMZbnhy7JoBmIDmdTykejqQcYkFlOngjfSu__g`;
    }
  }

  if (config.headers.Authorization === null) {
    delete config.headers.Authorization;
  }

  return config;
});

axiosClient.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err?.response?.data?.errors?.find((e) => e === "Token expired")) {
      delete err.config.headers?.Authorization;
      authmanager.logout();
      return axiosClient.request(err.config);
    } else {
      throw err;
    }
  }
);

export default axiosClient;
