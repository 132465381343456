import { cart } from "../utils/cart";
import React, { useState, useEffect } from "react";
import axiosClient from "../utils/axios";
import useLoggedGuard from "../utils/useLoggedGuard";
import useCurrentAccount from "../utils/useCurrentAccount";
import SigninForm from "../components/SigninForm";
import RegisterForm from "../components/RegisterForm";
import ScheduleTile from "../components/ScheduleTile";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const CheckoutCartItem = ({ item }) => {
  return (
    <ScheduleTile
      schedule={item}
      controls={[
        <div className="inline-flex items-center">
          <button
            onClick={() => cart.next(cart.getValue().filter((i) => i !== item))}
            className="text-white inline-flex w-full justify-center rounded-md px-4 py-2 font-medium bg-primary focus:outline-none flex items-center hover:bg-primary-hover"
          >
            Annuler
          </button>
        </div>,
      ]}
    />
  );
};

const CheckoutContactLogin = () => {
  const LOGIN_MODES = {
    REGISTER: 1,
    SIGNIN: 2,
  };

  const [loginMode, setLoginMode] = useState(LOGIN_MODES.REGISTER);

  if (loginMode === LOGIN_MODES.SIGNIN) {
    return (
      <div>
        <SigninForm />

        <button
          type="button"
          className="mt-1 text-sm text-primary hover:underline"
          onClick={() => setLoginMode(LOGIN_MODES.REGISTER)}
        >
          Pas encore inscrit ?
        </button>
      </div>
    );
  }

  return (
    <div>
      <RegisterForm />
      <button
        type="button"
        className="mt-1 text-sm text-primary hover:underline"
        onClick={() => setLoginMode(LOGIN_MODES.SIGNIN)}
      >
        Déjà inscrit ?
      </button>
    </div>
  );
};

const CheckoutContactResume = () => {
  const account = useCurrentAccount();

  if (!account) {
    return null;
  }

  return (
    <h2 className="text-2xl text-primary">
      La réservation sera faite au nom de{" "}
      <strong>{account.attributes.fullname}</strong>.
    </h2>
  );
};

const CheckoutContact = () => {
  const logged = useLoggedGuard();

  if (logged) {
    return null;
  }

  return (
    <div className="max-w-md mx-auto">
      <CheckoutContactLogin />
    </div>
  );
};

const Checkout = () => {
  const logged = useLoggedGuard();
  const [confirmed, setConfirmed] = useState(false);
  const [items, setItems] = useState(cart.getValue());

  useEffect(() => {
    const sub = cart.subscribe(setItems);

    return () => sub.unsubscribe();
  }, []);

  const _checkout = async () => {
    if (!logged) {
      return;
    }

    await Promise.all(
      items.map(async (item) => {
        await axiosClient.post("/api/d2/contacts/schedules", {
          data: {
            attributes: {
              duration: 60,
              calendar_id: item.calendar_id,
              public_read: true,
              start: item.from,
            },
          },
        });
      })
    );

    cart.next([]);
    setConfirmed(true);
  };

  if (confirmed) {
    return (
      <div>
        <header>
          <div className="px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight text-gray-900 mb-3">
              Votre réservation a bien été prise en compte !
            </h1>
            <Link
              to="/bookings"
              className="text-primary hover:underline flex items-center"
            >
              Voir mes réservations
              <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
            </Link>
          </div>
        </header>
      </div>
    );
  }

  if (!items?.length) {
    return (
      <div>
        <header>
          <div className="px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight text-gray-900">
              Votre panier est vide
            </h1>
          </div>
        </header>
      </div>
    );
  }

  return (
    <div>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="space-y-10">
          <CheckoutContact />
          <div className="space-y-4">
            {items.map((item) => (
              <CheckoutCartItem item={item} />
            ))}
          </div>
          {items?.length && logged ? (
            <div>
              <button
                type="button"
                className="inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                onClick={_checkout}
              >
                Valider la réservation
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Checkout;
