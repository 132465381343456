import React, { useState } from "react";
import authmanager from "../utils/authmanager";

const SigninForm = ({ loading, onSubmit }) => {
  const [_loading, setLoading] = useState(false);
  const [values, setValues] = useState({});

  const _handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);

    try {
      await authmanager.login(values);

      onSubmit && onSubmit();
    } catch (e) {
      alert("Une erreur est survenue lors de la connexion");
    } finally {
      setLoading(false);
    }
  };

  loading = loading || _loading;

  return (
    <form
      className={`space-y-6 ${loading ? "opacity-50" : "opacity-100"}`}
      action="#"
      method="POST"
      onSubmit={_handleSubmit}
    >
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          {" "}
          Adresse email{" "}
        </label>
        <div className="mt-1">
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
            disabled={loading}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            value={values.username}
            onChange={({ currentTarget: { value: username } }) =>
              setValues((c) => ({ ...c, username }))
            }
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="password"
          className="block text-sm font-medium text-gray-700"
        >
          {" "}
          Mot de passe{" "}
        </label>
        <div className="mt-1">
          <input
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            required
            disabled={loading}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            value={values.password}
            onChange={({ currentTarget: { value: password } }) =>
              setValues((c) => ({ ...c, password }))
            }
          />
        </div>
      </div>
      <div>
        {loading ? (
          <button
            type="button"
            className="inline-flex justify-center rounded-md px-4 py-2 text-sm font-medium border border-gray-300 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 opacity-50"
          >
            Chargement ...
          </button>
        ) : (
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            Connexion
          </button>
        )}
      </div>
    </form>
  );
};

export default SigninForm;
