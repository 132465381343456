import Navbar from "./Navbar";
import Appbar from "./Appbar";
import { BehaviorSubject } from "rxjs";
import { useState, useEffect } from "react";

export const layoutCurrentCalendar = new BehaviorSubject(null);

const Layout = ({ children }) => {
  const [calendar, setCalendar] = useState();

  useEffect(() => {
    const sub = layoutCurrentCalendar.subscribe(setCalendar);

    return () => sub.unsubscribe();
  }, []);

  return (
    <div className="h-screen bg-layout bg-cover flex flex-col">
      <Appbar />
      <div className="w-full py-10 h-0 flex-grow overflow-auto">
        <header className="w-full max-w-screen-xl mx-auto hidden sm:block mb-10">
          <h1 className="text-3xl font-bold leading-tight text-gray-900 text-white max-w-lg">
            Bienvenue sur votre espace de réservation
          </h1>
        </header>
        <div className="">
          <div className="w-full max-w-screen-xl mx-auto">
            <div className="flex items-start space-x-10">
              <div className="bg-white w-full">
                <Navbar />
                <div className="py-10">{children}</div>
              </div>
              {calendar ? (
                <div className="w-1/3 flex-shrink-0 bg-white">
                  <img
                    src="/images/calendar-hero.jpg"
                    alt={calendar.attributes.title}
                    className="w-full h-64 object-cover"
                  />

                  <div className="space-y-4 px-4 sm:px-6 lg:px-8 py-8">
                    <h1 className="text-3xl font-bold leading-tight text-gray-900">
                      {calendar.attributes.title}
                    </h1>

                    <div
                      dangerouslySetInnerHTML={{
                        __html: calendar.attributes.description,
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="w-1/3 flex-shrink-0">
                  <img src="/images/logo-white.png" className="w-56 mx-auto" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
