import React from "react";
import useLoggedGuard from "../utils/useLoggedGuard";
import Login from "./Login";
import useCurrentAccount from "../utils/useCurrentAccount";
import ProfileForm from "../components/ProfileForm";

const Account = () => {
  const logged = useLoggedGuard();
  const account = useCurrentAccount();

  if (!logged || !account) {
    return <Login />;
  }

  return (
    <div>
      <header>
        <div className="px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900 mb-8">
            Mes informations
          </h1>
          <ProfileForm account={account} />
        </div>
      </header>
    </div>
  );
};

export default Account;
